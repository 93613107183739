import React from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";

import { toast } from "react-toastify";
import TimerBlock from "./TimerBlock";
import useDayjsFormat from "./useDayjsFormat";
import { normalCard, normalPhone, normalPrice } from "../utils/regExp";

const Info = ({ payment, getPaymentInfo }) => {
  const inputReqData = payment?.note?.phone || payment?.note?.cardpan || "";
  const inputSum = payment?.amount_to_pay;
  const { getFullDate, getFullTime } = useDayjsFormat();

  const handleCopy = () => {
    toast.success("Скопировано");
  };

  const handleClick = async () => {
    await getPaymentInfo(payment?.guid, true);
  };

  const handleTimer = () => {
    window.location.reload();
  };
  return (
    <div className="payment-system__step" data-step="1">
      <div className="payment-system__title">Окно оплаты</div>
      <div className="payment-system-message">
        Заказ успешно <span className="text-orange">принят</span>
      </div>
      <div className="payment-system-message payment-system-message_next">
        Сделайте перевод <span className="text-danger">точной суммы</span> на{" "}
        {inputReqData[0] === "+"
          ? "указанный номер телефона"
          : "указанную карту"}
        .
      </div>

      <div>
        <div className="payment-system-card">
          <div className={"input"}>
            <label htmlFor="card">
              {inputReqData[0] === "+" ? "Номер телефона" : "Номер карты"}
            </label>

            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  paddingRight: 50,
                }}
              >
                {inputReqData[0] === "+"
                  ? normalPhone(inputReqData.slice(1))
                  : normalCard(inputReqData)}
              </div>
              <CopyToClipboard text={inputReqData} onCopy={handleCopy}>
                <i className="copy"></i>
              </CopyToClipboard>
            </div>
          </div>

          {payment?.expired && (
            <div className="card flex-column">
              <div className="text">Оплатить до</div>
              <div className="text text-white">
                {getFullTime(payment?.expired)}
                {", "}
                {getFullDate(payment?.expired)} <br />
                <TimerBlock
                  expiryTime={payment?.expired}
                  callback={handleTimer}
                />
              </div>
            </div>
          )}
        </div>

        {payment?.note?.fio && (
          <div className={"input"} style={{ marginTop: 20 }}>
            <label htmlFor="card">ФИО</label>

            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  paddingRight: 50,
                }}
              >
                {payment?.note?.fio}
              </div>
              <CopyToClipboard text={payment?.note?.fio} onCopy={handleCopy}>
                <i className="copy"></i>
              </CopyToClipboard>
            </div>
          </div>
        )}

        <div className="payment-system-amount">
          <div className={"input input-danger"} style={{ width: "100%" }}>
            <label htmlFor="amount">Сумма</label>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                {normalPrice(inputSum)}
              </div>
              <CopyToClipboard text={inputSum} onCopy={handleCopy}>
                <i className="copy"></i>
              </CopyToClipboard>
            </div>
          </div>

          <button className="btn btn_lg btn-white" onClick={handleClick}>
            Я оплатил
          </button>
        </div>

        <div className="payment-system-input-text">
          Будьте <span className="text-danger">внимательны и точны</span> в
          сумме перевода!
        </div>
      </div>
    </div>
  );
};

export default Info;
