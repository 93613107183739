import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import useDayjsFormat from "./Pay/useDayjsFormat";
import Loading from "./Pay/Loading";
import Form from "./Pay/Form";
import Info from "./Pay/Info";
import ResultPage from "./Pay/Result";
import { TelegramIcon } from "./Pay/Icons";

const PaymentForm = () => {
  const { guid } = useParams();
  const [paymentData, setPaymentData] = useState();
  const [resultText, setResultText] = useState(null);
  const [resultType, setResultType] = useState(null);
  const [loading, setLoading] = useState(true);
  const { isExpired } = useDayjsFormat();

  useEffect(() => {
    const handleFocus = () => {
      getPaymentInfo(guid);
    };
    window.addEventListener("focus", handleFocus);
    return () => {
      window.removeEventListener("focus", handleFocus);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getPaymentInfo(guid);
    // eslint-disable-next-line
  }, [guid]);

  const payStart = (email, method, bank) => {
    setLoading(true);
    axios
      .post("/merchant/order/create/from/draft", {
        guid,
        method,
        email,
        bank,
      })
      .then((response) => {
        setPaymentData(response.data);
      })
      .catch((error) => {
        setResultType("error");
        setResultText(
          error?.response?.data?.error ||
            "Не удалось выполнить запрос! Повторите попытку позже"
        );
        // console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getPaymentInfo = async (guid, isFinish = false) => {
    if (guid) {
      setLoading(true);
      axios
        .get("/merchant/order/info", { params: { guid } })
        .then(({ data }) => {
          const { status, note } = data?.payment;
          if (status >= 1) {
            setResultText("Заказ успешно оплачен");
            setResultType("success");
          } else {
            if (isFinish) {
              toast.error("Оплата не была получена");
            }
          }
          if (note?.url) {
            window.location = note?.url;
          } else {
            setPaymentData(data);
          }
        })
        .catch((error) => {
          setResultText(
            error?.response?.data?.error ||
              "Не удалось выполнить запрос! Повторите попытку позже."
          );
          setResultType("error");
          // console.log(error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <div className="payment-system">
      <div className="wrapper">
        {loading ? (
          <Loading />
        ) : resultType ? (
          <ResultPage
            url={paymentData?.payment?.result_url}
            type={resultType}
            text={resultText}
          />
        ) : paymentData ? (
          <div>
            {paymentData?.payment.status === -1 && (
              <Form paymentData={paymentData} payStart={payStart} />
            )}
            {paymentData?.payment.status === 0 &&
              !isExpired(paymentData?.payment?.expired) && (
                <Info
                  payment={paymentData?.payment}
                  getPaymentInfo={getPaymentInfo}
                />
              )}
            {paymentData?.payment.status === 0 &&
              isExpired(paymentData?.payment?.expired) && (
                <ResultPage type={"error"} text={"Истек срок оплаты платежа"} />
              )}
          </div>
        ) : null}
      </div>
      <div className="payment-system__info">
        <div className="logo">RosPlat</div>
        <a
          href={paymentData?.supportTelegramLink}
          target="_blank"
          rel="noopener noreferrer"
          className="contact-us"
        >
          <TelegramIcon />
          <span>Связь с тех. поддержкой</span>
        </a>
      </div>
    </div>
  );
};

export default PaymentForm;
